<template>
    <div>
        <hero/>
        <contents />
    </div>
</template>

<script>

    export default {
        components: {
            Hero: () => import ('@/components/imprint/Hero'),
            Contents: () => import('@/components/imprint/Contents')
        },
        mounted(){
            this.$store.dispatch('app/setLink', -1)
        }
    }
</script>
